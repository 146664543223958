@import 'back2top';

.responsive-menu-trigger {
  .top,
  .mid,
  .bottom {
    transition: all 0.2s;
  }

  &.active {
    .top {
      top: $meun-top-bar-position;
      transform: rotate3d(0, 0, 1, 45deg);
      &.thin {
        top: $meun-top-bar-thin-position;
      }
    }
    .mid {
      opacity: 0;
    }
    .bottom {
      top: $menu-bottom-bar-position;
      transform: rotate3d(0, 0, 1, -45deg);
      &.thin {
        top: $menu-bottom-bar-thin-position;
      }
    }
  }
}
.responsive-menu {
  margin: 0;
  max-height: 0;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.2s ease;
  width: 100%;
  .menu-item {
    width: 100%;
    border-bottom: 1px dotted white;
    a {
      //color:white !important;
      display: block;
      font-size: 20px;
      padding: 10px 0;
      text-align: center;
    }
    .menu-item-wrap {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      a {
        flex: 1;
        text-align: left;
      }
      i {
        display: none;
        position: absolute;
        right: 0;
        width: 60px;
        font-size: 20px;
      }
    }
    .sub-menu {
      max-height: 0;
      overflow: hidden;
      a {
        padding-left: 10px;
      }
      .sub-menu a {
        padding-left: 20px;
      }
      & > .menu-item:last-child {
        border-bottom: 0 !important;
      }
      &.show {
        max-height: none;
      }
    }
  }
  &.show {
    z-index: 99;
    .menu-item-wrap {
      i {
        display: initial;
      }
    }
  }
}
cite {
  display: block;
}

@include text-select-colour($text-select-colour);
// Form
@media screen and (max-width: 640px) {
  .me-Form,
  .bldform {
    .row > .columns {
      flex: 1 1 100%;
    }
  }
}
// *****************
// Site Info
// *****************
.me-SiteInfo {
  @media #{$large-up} {
    .link-disable {
      pointer-events: none;
    }
  }
}

.me-doc-body {
  position: relative;
}

.me-block.me-Image.transition-Slide {
  position: relative;
  overflow: hidden;
  .slide {
    position: absolute;
    top: 0;
    &.active {
      top: -100%;
    }
  }

  .follow {
    position: absolute;
    top: 100%;
    &.active {
      top: 0;
    }
  }
}

.me-Heading {
  @for $i from 1 through 6 {
    h#{$i} {
      white-space: pre-line;
    }
  }
}

.me-Statement {
  cite {
    white-space: pre-line;
  }
}

.layer-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// Formatting for Block - Anchor
.me-block.me-Anchor {
  position: relative;
  a {
    z-index: -1;
  }
}

// Formatting for Block - Carousel
.me-Carousel {
  .item.column {
    display: flex !important;
  }
}

.me-VideoBg {
  // Formatting for Block - Video Background
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 85vh;
  max-height: 56.25vw;
  overflow: hidden;

  .vb {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
    padding-bottom: 56.25%;
    //position: relative;
    //top: 0;
    //right: 0;
    //bottom: 0;
    //left: 0;
    //z-index: 1;
    //margin: auto;
    transition: opacity 0.5s;
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
  }
}

.naked-button {
  align-items: normal;
  background: none;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}

@supports (-moz-appearance: none) {
  /* Mozilla-only */
  .naked-button::-moz-focus-inner {
    /* reset any predefined properties */
    border: none;
    padding: 0;
  }
  .naked-button:focus {
    /* add outline to focus pseudo-class */
    outline-style: dotted;
    outline-width: 1px;
  }
}
